<div *ngIf="docs$ | async as docs ; else loading;" >
    <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngFor="let doc of docs" (click)="onLoad(doc.RECID)"  >
            <mat-icon *ngIf="!(bizy$ | async) ; else downloading;" >file_download</mat-icon> &nbsp;
            {{ doc.NAME + '.' + doc.FILETYPE }} &nbsp;
            <ng-template #downloading >
                <mat-spinner  [diameter]="24" ></mat-spinner>    
            </ng-template>
        </mat-chip>
      </mat-chip-list>
</div> 
<ng-template #loading > <p class="p-2 m-2" >гружуся потихонечку... </p></ng-template>
