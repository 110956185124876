import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, NEVER, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PurchasesService } from '../shared/services/purchases.service';

@Component({
  selector: 'app-purch-card',
  templateUrl: './purch-card.component.html',
  styleUrls: ['./purch-card.component.css']
})
export class PurchCardComponent implements OnInit {

  purch$:Observable<any> = NEVER;

  constructor(private route: ActivatedRoute, private purchServ : PurchasesService) { }

  ngOnInit(): void {
    this.purch$ = this.route.params.pipe( 
      switchMap( x => this.purchServ.getPurchaseById$( x?.id)  ),
      map( x => x.length > 0 ? x[0]: null)
    )

    this.purch$.subscribe(x=>console.log(x))

    //this.route.url

  }

}
