
<!-- (change)="onValChange($event.value)"  -->
<mat-card style="margin:20px ; background-color:rgba(0,0,0,.03);  ">
    <!-- <mat-card-title> Закупки  </mat-card-title> -->
    <mat-card-subtitle>
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style"> 
            <mat-button-toggle *ngFor="let item of this.periods$ | async "
                [value] = "{ yr:item.YEAR, cnt:item.COUNT_PUB, ind:0 }"
                (change)="year$.next($event.value)" 
                >
                <div style="line-height: 32px" > <h3>{{ item.YEAR }}</h3></div>
                <div style="line-height: 24px" >{{ item.label }}</div>
            </mat-button-toggle>    
        </mat-button-toggle-group>
    </mat-card-subtitle>

    <mat-paginator style="background-color:rgba(0,0,0,0);"  #paginator [length]="( year$ | async)?.cnt" [pageIndex] = "( year$ | async)?.ind"  [pageSizeOptions]="[10, 50, 100]" aria-label="Select page"
        (page)="page$.next({  page:$event.pageIndex , perPage: $event.pageSize})"
    >
    </mat-paginator>

    <div *ngFor="let purch of this.purchesSubj$ | async ">

        <ng-container *ngIf=" (purch) ; else loading " >
            <app-purch-short-card [purch] = "purch" ></app-purch-short-card>
        </ng-container>    

        <ng-template #loading>
            <mat-card style="margin-bottom: 15px;">
                <mat-card-header style="width: 100%;"> 
                        <div mat-card-avatar  >
                            <button mat-mini-fab  disabled   >
                            </button>
                        </div>
                       <mat-card-title style=" background-color: lightgray; color:lightgray; ;"> 
                        e
                       </mat-card-title>
                       <mat-card-subtitle style="width: 100% ; background-color: lightgray; color:lightgray" >
                            eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
                        </mat-card-subtitle>    
                </mat-card-header> 
               
                <mat-card-content>
                    <!-- <p style=" background-color: lightgray; color:lightgray; "> eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee </p>
                    <p style=" background-color: lightgray; color:lightgray; "> eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee </p>
                    <p style=" background-color: lightgray; color:lightgray; "> eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee </p> -->
                    <mat-spinner style="margin:0 auto; ;"    mode="indeterminate"  [diameter]="50" ></mat-spinner>    
                    <button mat-raised-button disabled >Подробно...</button>
                </mat-card-content>
              </mat-card>
        </ng-template>

    </div>


</mat-card>




