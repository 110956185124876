import { anyEntityOptions, AnyEntityId } from "./any-entity";

export const NvaPlanPurchaseLine:anyEntityOptions<AnyEntityId> = {
  name: "NVA_EX_PlanPurch", 
  location:"/NvaAx/NVA_EX_PlanPurch", 
  selectId: (x) => x.RECID,
  selBack: (x:string) => ("?RECID=" + x )
};    

export const NVA_EX_PlanPurch_Docs:anyEntityOptions<AnyEntityId> = {
  name: "NVA_EX_PlanPurch_Docs", 
  location:"/NvaAx/NVA_EX_PlanPurch_Docs", 
  selectId: (x) => x.RECID,
  selBack: (x:string) => ("?RECID=" + x )
};   


export interface PurchDocValEntity {
  RECID:number
  FILE:string
  FILENAME:string
}

export const NVA_EX_PlanPurch_Doc_Val:anyEntityOptions<PurchDocValEntity> = {
  name: "NVA_EX_PlanPurch_Doc_Val", 
  location:"/NvaAx/NVA_EX_PlanPurch_Doc_Val", 
  selectId: (x) => x.RECID,
  selBack: (x:string) => ("?RECID=" + x )
};    



export interface PurchPeriodsEntity {
   YEAR:number
   JOURNALS:number
   COUNT_ALL:number 
   COUNT_PUB:number
}

export const NVA_EX_Purch_Periods:anyEntityOptions<PurchPeriodsEntity> = {
  name: "NVA_EX_Purch_Periods", 
  location:"/NvaAx/NVA_EX_Purch_Periods", 
  selectId: (x) => x.YEAR,
  selBack: (x:string) => ("?YEAR=" + x )
};    





// export const DOCUVALUE:anyEntityOptions<AnyEntityId> = {
//   name: "DOCUVALUE", 
//   location:"/NvaAx/DOCUVALUE", 
//   selectId: (x) => x.RECID,
//   selBack: (x:string) => ("?RECID=" + x )
// };    