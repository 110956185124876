import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NvaviaHeaderComponent } from './nvavia-header/nvavia-header.component';
import { NvaviaFooterComponent } from './nvavia-footer/nvavia-footer.component';
import { HttpClientModule } from '@angular/common/http';
import { PurchSelectorComponent } from './purch-selector/purch-selector.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorModule} from '@angular/material/paginator';
import { PurchShortCardComponent } from './purch-short-card/purch-short-card.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { PurchCurPipe, PurchLawPipe, PurchMethodExPipe, PurchMethodPipe, PurchStatePipe } from './shared/purch-enums.pipe';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import { PurchDocsComponent } from './purch-docs/purch-docs.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PurchCardComponent } from './purch-card/purch-card.component';

@NgModule({
  declarations: [
    AppComponent,
    NvaviaHeaderComponent,
    NvaviaFooterComponent,
    PurchSelectorComponent,
    PurchShortCardComponent,
    PurchMethodPipe,
    PurchMethodExPipe,
    PurchStatePipe,
    PurchLawPipe,
    PurchCurPipe,
    PurchDocsComponent,
    PurchCardComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
    MatButtonToggleModule,
    MatCardModule,
    MatPaginatorModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
