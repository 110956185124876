<div class="container-fluid text-center" >
  <app-nvavia-header></app-nvavia-header>
</div>

<router-outlet></router-outlet>

<div class="container-fluid text-center" >
  <app-nvavia-footer></app-nvavia-footer>
</div>

