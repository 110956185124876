import { Component, Injectable, OnInit } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BehaviorSubject, combineLatest, NEVER, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { AnyEntity } from '../shared/any-entity';
import { PurchasesService } from '../shared/services/purchases.service';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  firstPageLabel = `Первая страница`;
  itemsPerPageLabel = `Строк на странице`;
  lastPageLabel = `Последняя страница`;
  nextPageLabel = 'Следующая страница';
  previousPageLabel = 'Предыдущая страница';
  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Стр. 1 из 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Стр. ${page + 1} из ${amountPages}`;
  }
}


@Component({
  selector: 'app-purch-selector',
  templateUrl: './purch-selector.component.html',
  styleUrls: ['./purch-selector.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})

export class PurchSelectorComponent implements OnInit {

  periods$ : Observable<any> = NEVER;  
  year$ : Subject< { yr:number, cnt:number, ind:number } > = new Subject();  // eject from html
  page$ : Subject< {  page:number , perPage:number  } > = new Subject();  // eject from html
  purches$ : Observable<AnyEntity[]> = new Subject();  
  bizy$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  
  purchesSubj$ : BehaviorSubject<AnyEntity[]> = new BehaviorSubject<AnyEntity[]>([]);  
  
  constructor( private purchServ : PurchasesService ) { }

  ngOnInit(): void {

     this.periods$ = this.purchServ.getPeriods$.pipe(
       map( xs => xs.map( x => ({...x , label:  x.COUNT_ALL+"/"+x.COUNT_PUB })  ))
     ) ;


    this.purches$ =  combineLatest([ this.year$, this.page$.pipe(startWith({  page:1 , perPage:10  }))]).pipe(
      tap( x => this.bizy$.next(true)),
      tap( x => this.purchesSubj$.next( new Array<AnyEntity>( x[1].perPage ) )),
      switchMap( x =>  this.purchServ.getPurchaseByYear$(x[0].yr, x[1].perPage, x[1].page ) ),
      tap( x => this.purchesSubj$.next(x)),
      tap( x => this.bizy$.next(false))
    ) 

    
    this.purches$.subscribe( x => console.log(x)  );  
    this.bizy$.subscribe( x => console.log(x)  ); 


  }

}
