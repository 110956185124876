export class AppSettings {

    svcFasadeUri1: string  = "http://webgate.nvavia.ru:8080/api" ;
    svcFasadeUri2: string = "http://b-portal.nvavia.ru/api" ;
    svcFasadeUri: string = "http://back.nvavia.ru/api" ;
    svcRestMetadataSuffix: string ="/Md" ;
    svcRestRecTemplateSuffix: string ="/!Template" ;

    

 }
   