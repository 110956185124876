import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurchCardComponent } from './purch-card/purch-card.component';
import { PurchSelectorComponent } from './purch-selector/purch-selector.component';

const appRoutes: Routes = [
  { path: '',               component: PurchSelectorComponent, pathMatch: 'full'  },
  { path: 'purch/:id',       component: PurchCardComponent , pathMatch: 'full' },
  { path: '**',             component: PurchSelectorComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
