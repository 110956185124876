import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NEVER, Observable } from 'rxjs';
//import { HttpClient } from '@angular/common/http';
import {  map } from 'rxjs/operators';

@Component({
  selector: 'app-nvavia-header',
  templateUrl: './nvavia-header.component.html',
  styleUrls: ['./nvavia-header.component.css']
})
export class NvaviaHeaderComponent implements OnInit {

  ld$ : Observable<any> = NEVER;


  constructor(private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit() {
    this.ld$ = this.http.get('https://www.nvavia.ru/CntHolder.ashx?iid=JSINIT:NVAWebControl.WeatherBox', {responseType: 'text' } ).pipe(
      map(x => this.sanitizer.bypassSecurityTrustHtml(x) )
     );

     //this.ld$.subscribe(x=>console.log(x))

  }


}
