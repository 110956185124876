import { Pipe, PipeTransform } from '@angular/core';
import { toCyrDescr, toLawDescr, toPurchMethodDescr, toStateDescr } from './services/purchases.service';

@Pipe({   name: 'purchMethod' })
export class PurchMethodPipe implements PipeTransform {  transform = toPurchMethodDescr }


@Pipe({   name: 'purchMethodEx' })
export class PurchMethodExPipe implements PipeTransform {  
  transform = ( id:number , isEl:boolean ) => toPurchMethodDescr(id) +  (isEl?" в электронной форме ":"")
  
}

@Pipe({   name: 'purchState' })
export class PurchStatePipe implements PipeTransform {  transform = toStateDescr }

@Pipe({   name: 'purchLaw' })
export class PurchLawPipe implements PipeTransform {  transform = toLawDescr }

@Pipe({   name: 'purchCur' })
export class PurchCurPipe implements PipeTransform {  transform = toCyrDescr }

