<div  class="head-container">
     
   <div *ngIf="ld$ | async as ld"  class="slogan-container" [innerHtml]="ld"  >     </div> 
   
           <div class="header-logo">
               <a href="https://www.nvavia.ru">
                 <img src="https://www.nvavia.ru/Drawer.ashx?ImageKey=nva2">
               </a>
           </div>
   
           <div class="header-logo-txt">
           </div>
   
           <div class="slogan-subcontainer">
             <a href="http://www.gismeteo.ru/city/daily/3974/">gismeteo.ru</a>
           </div>
   
           <div class="contact-subcontainer">
             <p class="info1">справочная служба аэропорта </p>
             <p class="info2">006, +7 3466 492175</p>
           </div>
   
           <div class="top-menu-container"  style="box-sizing:inherit">
             <span id="PT5698">
           <ul>
               <li>
                 <a href="https://www.nvavia.ru/Home/Show?_id=2&amp;_cl=t"  style="background-image: url('https://www.nvavia.ru/Drawer.ashx?ImageKey=Tmi_2_a');">ПАССАЖИРАМ</a>
               </li>
               <li>
                 <a href="https://www.nvavia.ru/Home/Show?_id=5&amp;_cl=t" class="current" style="background-image: url('https://www.nvavia.ru/Drawer.ashx?ImageKey=Tmi_5');">ПАРТНЕРАМ</a>
               </li>
               <li>
                 <a href="https://www.nvavia.ru/Home/Show?_id=10&amp;_cl=t" style="background-image: url('https://www.nvavia.ru/Drawer.ashx?ImageKey=Tmi_10');">ИНФОРМАЦИЯ</a>
               </li>
               <li>
                 <a href="https://www.nvavia.ru/Home/Show?_id=71&amp;_cl=t" style="background-image: url('https://www.nvavia.ru/Drawer.ashx?ImageKey=Tmi_71');">О ПРЕДПРИЯТИИ</a>
               </li>
             </ul>
             </span>
           </div>
   
       </div>
   
       <div id="Lase" class="nav-container" style="top:130px">
       <a href="https://www.nvavia.ru/Home/Show?_id=1">Главная</a>
       →
       <a href="https://www.nvavia.ru/Home/Show?_id=5">Партнерам</a>
       →
       <a href="https://www.nvavia.ru/Home/Show?_id=68">Закупки</a>
       </div>
