import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AnyEntity } from '../shared/any-entity';
import { PurchasesService } from '../shared/services/purchases.service';


@Component({
  selector: 'app-purch-docs',
  templateUrl: './purch-docs.component.html',
  styleUrls: ['./purch-docs.component.css']
})
export class PurchDocsComponent implements OnInit {

  @Input() purchId:number = 0;  

  docs$:Observable<AnyEntity[]> = NEVER;

  bizy$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false) ;


  constructor(private purchServ : PurchasesService) { }

  ngOnInit(): void {
    this.docs$ = this.purchServ.getPurchaseDocs$(this.purchId);

    //this.docs$.subscribe(x=> console.log(x));
    //this.bizy$.subscribe(x=> console.log(x));
    //getPurchaseDoc$
  }

  onLoad = (docId:number) => of(docId).pipe(
      take(1),
      tap( _ => this.bizy$.next(true) ),
      switchMap( id => this.purchServ.getPurchaseDoc$(id) ),
      tap( _ => this.bizy$.next(false) ),
  ).subscribe(x =>this.download(x[0].FILE,x[0].FILENAME))


  //подгорбаток-кринж
  public download(text:string, filename:string){
    var element = document.createElement('a');
    element.setAttribute('href', 'data:image/png;base64,' + decodeURIComponent(text)) ;//encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
