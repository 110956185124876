<ng-container *ngIf="( purch$ | async) as purch; else loading " > 
    
    <mat-card   style="margin-left: 30px;margin-right: 30px;" >
        <mat-card-header style="width: 100%;"> 
            <div  *ngIf="(purch.PLANPURCHASEPUBLICSTATE == 1); else cls; "  mat-card-avatar  >
                <button mat-mini-fab color="primary"  [matTooltip] = "purch.PLANPURCHASEPUBLICSTATE | purchState " >
                    <mat-icon>drafts</mat-icon> 
                </button>
            </div>
            <ng-template #cls>
                <div mat-card-avatar  >
                    <button mat-mini-fab color="accent" [matTooltip] = "purch.PLANPURCHASEPUBLICSTATE | purchState ">
                        <mat-icon>highlight_off</mat-icon> 
                    </button>
                </div>
            </ng-template>
            <mat-card-title style="width: 100%;"> 
                  № {{ purch.EXTERNALID }} ({{  purch.RECID}}) 
            </mat-card-title>
            <mat-card-subtitle style="width: 100%" >
                <h3>    {{ purch.SUBJECTOFCONTRACT }} </h3>
            </mat-card-subtitle>
            
        </mat-card-header> 
       
        <mat-card-content>
            <div class = "p-cnt"   >
                <div class = "p-cnt1">
                  <h4 > {{ purch.PURCHASEMETHOD | purchMethodEx  : purch.ISDIGITAL }} </h4>
                  <h5 > {{ purch.PLANPURCHASEPUBLICSTATE | purchState }} </h5>
                  <small style="color: blue;">{{purch.PUBLICDATE | date:'dd.MM.yy'}} / {{purch.PUBLICUPDATEDATE | date:'dd.MM.yy'}}</small>
                  <p class = "p-cnt-warn" > {{purch.PLANPURCHASELAW | purchLaw}} </p>
                 
                </div>    
    
                <div class = "p-cnt2"> 
                    <div class = "p-cnt" >
                        <div class = "p-cnt2-1">
                            <p class = "p-cnt-desc">Начальная цена</p>
                            <h4 >{{purch.MAXPRICE | number : '1.2-2' }} </h4>
                            <p class = "p-cnt-desc"> {{ purch.CURRENCY | purchCur }}</p>  
                        </div>    
                        <div class = "p-cnt2-2">
                            <p class = "p-cnt-desc">Количество</p>
                            <h4 >{{purch.QTY}} </h4>
                            <p class = "p-cnt-desc"> {{ purch.UNITID }}</p>  
                        </div>    
                    </div>
                    <app-purch-docs [purchId] = "purch.RECID" ></app-purch-docs> 
    
                </div>    
            </div>    
        </mat-card-content>
      </mat-card>    
</ng-container>
<ng-template #loading>
    Loading...
</ng-template>
