import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AnyEntity, anyEntityOptions } from '../any-entity';
import { AppSettings } from '../app-setting';
import { NvaPlanPurchaseLine, NVA_EX_PlanPurch_Docs, NVA_EX_PlanPurch_Doc_Val, NVA_EX_Purch_Periods, PurchDocValEntity, PurchPeriodsEntity } from '../entity-options';

// const buildSimplUriForOption = ( opt:anyEntityOptions ) => 

//     AppSettings.s.svcFasadeUri 

const YEAR_FLD = "YEAR";
const REFREC_FLD = "REFRECID";
const LIMIT_KEY = "LIMIT";
const OFFSET_KEY = "OFFSET";

export const toPurchMethodDescr = (id:number) =>
    id == 0 ? 'Конкурс ':
        id == 1 ? 'Запрос предложений':
            id == 2 ? 'Запрос котировок':
                id == 3 ? 'Аукцион':
                    id == 4 ? 'Закупка у единственного поставщика':
                        id == 4 ? 'Закупка у субъекта малого предпринимательства' : ' Неизвестный закупка';


// статус
export const toStateDescr = (id:number) =>
    id == 1 ? 'Подача заявок':
        id == 2 ? 'Процедура завершена':' Неизвестный статус';

// ФЗ
export const toLawDescr = (id:number) =>
    id == 0 ? '223-ФЗ':
        id == 1 ? '44-ФЗ':' Неизвестный ФЗ';

// ФЗ
export const toCyrDescr = (id:string) =>
    id == 'Руб' ? 'Российский рубль':' Неизвестный валюта';



@Injectable({
  providedIn: 'root'
})
export class PurchasesService {
  
  settings  = new AppSettings();

  constructor(private http: HttpClient) {  }

  private buildSimplUriForOption = ( opt:anyEntityOptions<any> ) =>  this.settings.svcFasadeUri + opt.location

  private buildUriForPurch = ( opt:anyEntityOptions<any>, year:number , limit: number , offset:number   ) =>  
      this.buildSimplUriForOption(opt) 
        + "?" + YEAR_FLD + "="+ year 
        + "&" + LIMIT_KEY + "="+ limit 
        + "&" + OFFSET_KEY + "="+ offset ;

  getPeriods$ = this.http.get<PurchPeriodsEntity[]>(this.buildSimplUriForOption(NVA_EX_Purch_Periods ))
  
  getPurchaseByYear$ = (year:number, limit: number = 0  , offset:number = 0 ) => 
      this.http.get<AnyEntity[]>(this.buildUriForPurch( NvaPlanPurchaseLine, year, limit, offset) )

  getPurchaseById$ = (id:any ) => 
      this.http.get<AnyEntity[]>(this.buildSimplUriForOption( NvaPlanPurchaseLine)  + NvaPlanPurchaseLine.selBack(id) ) 

  getPurchaseDocs$ = (purchId:number) =>
      this.http.get<AnyEntity[]>(this.buildSimplUriForOption( NVA_EX_PlanPurch_Docs) + "?" + REFREC_FLD + "=" + purchId.toString() )    

  getPurchaseDoc$ = (docId:number) => 
      this.http.get<PurchDocValEntity[]>(this.buildSimplUriForOption( NVA_EX_PlanPurch_Doc_Val) +  NVA_EX_PlanPurch_Doc_Val.selBack(docId.toString()) )       


}
