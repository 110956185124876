import { Component, Input, OnInit } from '@angular/core';
//import { toPurchMethodDescr } from '../shared/services/purchases.service';

@Component({
  selector: 'app-purch-short-card',
  templateUrl: './purch-short-card.component.html',
  styleUrls: ['./purch-short-card.component.css']
})
export class PurchShortCardComponent implements OnInit {

  @Input() purch:any;  

  constructor() { }

  ngOnInit(): void {
    console.log(this.purch);

  }

}
